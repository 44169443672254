import { Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <div className="container md:mx-auto max-w-page py-4 md:py-8">
        <h1 className="text-3xl md:text-4xl font-alegreya">404: Page Not Found</h1>
        <p className="text-gray-600 md:text-lg"><strong>Sorry this page does not exist.</strong></p>
        <p className="text-gray-600 md:text-lg">Please return to the <Link to={"/"} className="text-pink-700 underline md:no-underline md:hover:underline">Home page</Link>.</p>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo title="404: Not Found" />
)

export default NotFoundPage
